.App-div {
  /* min-height: 100%; */
  /* top: 10px; */
  /* padding: 40px 0px; */
  text-align: center;
  background: rgb(87, 255, 179);
  background: -moz-linear-gradient(
    270deg,
    rgba(87, 255, 179, 0.35) 35%,
    rgba(125, 100, 255, 0.35) 75%
  );
  background: -webkit-linear-gradient(
    270deg,
    rgba(87, 255, 179, 0.35) 25%,
    rgba(125, 100, 255, 0.35) 75%
  );
  background: linear-gradient(
    270deg,
    rgba(87, 255, 179, 0.35) 25%,
    rgba(125, 100, 255, 0.35) 75%
  );

  background: repeating-linear-gradient(
    -61deg,
    transparent,
    rgba(87, 255, 179, 0.35) 2px,
    transparent,
    rgba(255, 234, 74, 0.55) 1px,
    transparent,
    #7e64ff96 4px
  );
}
/* red, rgba(125, 100, 255, 0.5) 0 1px, transparent 0 20px */

.App {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.4);
  min-height: 100vh;
}
html,
body {
  height: 100%;
  background-color: whitesmoke;
  --mor-bg: #7d64ff;
  margin-top: 20px;
}
:root {
  --mor-bg: #7d64ff;
}

body {
  margin: 0px;
  padding: 0px;
}

.main {
  margin-top: 25px;
  margin-bottom: 20px;
  height: auto;
  min-height: 90%;
  /* min-width: 100%; */
  /* padding: 0 0 80px; */
  box-sizing: border-box;
  overflow: auto;
}

.lightThing {
  font-family: Montserrat-Light;
  color: var(--mor-bg);
}

.bordeB {
  border: 2px solid gray;
}
.instrucciones {
  font-size: 0.85em;
  color: gray;
  font-family: Montserrat-Light;
}

.bordeA {
  border: 1px solid red;
}

.testBorder-r {
  border: 1px solid blue;
}

.checkOption {
  color: var(--mor-bg);
  font-size: 0.75em;
  font-family: Montserrat-Bold;
}

.theNumber {
  color: black;
  font-family: Montserrat-Bold;
}

.theTitle {
  color: gray;
  font-family: Montserrat-ExtraBold;
}

.nextBtn {
  background-color: #57ffb3;
  margin: 22px auto;
  color: white;
  font-family: Montserrat-ExtraBold;
  text-transform: uppercase;
  border: none;
  padding: 7px 12px;
  box-shadow: 2px 2px 3px black;
}

.shakeThatThing {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;
  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 4px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 5px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.shakeThatLittleThing {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 1.25s;
  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  20% {
    transform: translate(-1px, 1px) rotate(1deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  60% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  100% {
    transform: translate(1px, -1px) rotate(-1deg);
  }
}

@font-face {
  font-family: Poppins-Regular;
  src: url(./styles/fonts/poppins/Poppins-Regular.ttf);
  /* src: url('../fonts/poppins/Poppins-Regular.ttf');  */
}

@font-face {
  font-family: Poppins-Medium;
  src: url(./styles/fonts/poppins/Poppins-Medium.ttf);
  /* src: url('../fonts/poppins/Poppins-Regular.ttf');  */
}

@font-face {
  font-family: Poppins-ExtraBold;
  src: url(./styles/fonts/poppins/Poppins-ExtraBold.ttf);
  /* src: url('../fonts/poppins/Poppins-Regular.ttf');  */
}

@font-face {
  font-family: Poppins-Light;
  src: url(./styles/fonts/poppins/Poppins-Light.ttf);
}

@font-face {
  font-family: Poppins-Thin;
  src: url(./styles/fonts/poppins/Poppins-Thin.ttf);
  /* src: url('../fonts/poppins/Poppins-Regular.ttf');  */
}

@font-face {
  font-family: Montserrat-ExtraBoldItalic;
  src: url(./styles/fonts/montserrat/Montserrat-ExtraBoldItalic.ttf);
  /* src: url('../fonts/poppins/Poppins-Regular.ttf');  */
}

@font-face {
  font-family: Montserrat-ExtraBold;
  src: url(./styles//fonts/montserrat/Montserrat-ExtraBold.ttf);
  /* src: url('../fonts/poppins/Poppins-Regular.ttf');  */
}

@font-face {
  font-family: Montserrat-Bold;
  src: url(./styles//fonts/montserrat/Montserrat-Bold.ttf);
  /* src: url('../fonts/poppins/Poppins-Regular.ttf');  */
}

@font-face {
  font-family: Montserrat-Light;
  src: url(./styles/fonts/montserrat/Montserrat-Light.ttf);
  /* src: url('../fonts/poppins/Poppins-Regular.ttf');  */
}


@font-face {
  font-family: Montserrat-Medium;
  src: url(./styles/fonts/montserrat/Montserrat-Medium.ttf);
  /* src: url('../fonts/poppins/Poppins-Regular.ttf');  */
}

:root {
  --color-fg: #7d64ff;
  --color-hover: #4934b3;
  --color-gray: #bebebe;
  --color-peach: #ff8f7d;
  --color-yellow: #ffe94a;
  --color-green: #57ffb3;
}