:root {
  --color-fg: #7d64ff;
  --color-hover: #4934B3;
  --color-peach:#FF8F7D;
  --color-yellow:#FFE94A;
  --color-green:#57FFB3;
}


.lds-dual-ring {
    display: inline-block;
    width: 164px;
    height: 164px;
  }
  
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 146px;
    height: 146px;
    margin: 1px;
    border-radius: 50%;
    border: 12px solid var(--color-fg);
    border-color: var(--color-green) transparent var(--color-fg) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  
  .loading-spinner__overlay {
    height: 99%;
    width: 99%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }