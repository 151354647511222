:root {
  --color-fg: #7d64ff;
  --color-hover: #4934b3;
  --color-peach: #ff8f7d;
  --color-yellow: #ffe94a;
  --color-green: #57ffb3;
}

.button {
  font: inherit;
  padding: 0.25rem 1.5rem;
  border: 1px solid var(--color-fg);
  border-radius: 4px;
  background: var(--color-fg);
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
  font-size: 0.85rem;
  font-family: Montserrat-Bold;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: var(--color-hover);
  border-color: var(--color-fg);
}

.button--inverse {
  background: transparent;
  color: var(--color-fg);
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: var(--color-fg);
}

.button--secondary {
  background: var(--color-peach);
  color: white;
}

.button--secondary:hover,
.button--secondary:active {
  color: var(--color-hover);
  background: var(--color-peach);
}

.button--secondaryInverse {
  border: 1px solid var(--color-peach);
  background: transparent;
  color: var(--color-peach);
}

.button--secondaryInverse:hover,
.button--secondaryInverse:active {
  color: var(--color-hover);
  background: var(--color-peach);
}

.button--danger {
  background: #830000;
  border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
  background: #f34343;
  border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.65rem;
  font-family: Montserrat-Bold;
}

.button--big {
  font-size: 1.5rem;
  font-family: Montserrat-ExtraBold;
}

.button--float {
  text-decoration: none;
  position: fixed;
  font-size: 1.5rem;
  padding: 0.25rem 0.5rem;
  bottom: 40px;
  left: 5px;
  background-color: none;
  color: #fff;
  border-radius: 90px;
  text-align: center;
  margin: auto;
  animation: nudge 3s ease infinite;
}

.button--float {
  cursor: pointer;
}

.animate-btn {
  animation: danceBtn 5s ease infinite;
}

@keyframes nudge {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(2rem, 0);
  }
}

@keyframes danceBtn {
  0%,
  100% {
    opacity: 0.75;
  }
  50% {
    opacity: 1;
    background: var(--mor-fg);
  }
}
